<template>
    <div class="settings-card">
		<h1 class="settings__heading">Password</h1>
    
		<div class="settings">
            <input class="settings__input" type="Password" value="" placeholder="Old Password">
			<input class="settings__input" type="Password" value="" placeholder="New Password">
			<input class="settings__input" type="Password" value="" placeholder="Repeat New Password">
		</div>
		<div class="settings__button-container"><button class="settings__button">save</button></div>
	</div>
</template>